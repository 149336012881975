.player-area {
	display: flex;

	&.bottom-left,
	&.bottom-right {
		.card-stack > :not(:first-child) {
			position: absolute;
			&:nth-child(2) {
				top: 4vmin;
			}
			&:nth-child(3) {
				top: 5vmin;
			}
			&:nth-child(4) {
				top: 4.5vmin;
				transform: rotate(90deg);
			}
		}
	}

	&.top-left,
	&.top-right {
		.card-stack > :not(:first-child) {
			position: absolute;
			&:nth-child(2) {
				top: 1vmin;
			}
			&:nth-child(3) {
				top: 2vmin;
			}
			&:nth-child(4) {
				top: 1.5vmin;
				transform: rotate(90deg);
			}
		}
	}

	&:not(.left) {
		width: 50%;
	}
	&.left {
		height: 100%;

		> .card-row {
			writing-mode: vertical-lr;
			height: 70%;
			> * {
				writing-mode: horizontal-tb;
			}
		}

		.card-stack > :not(:first-child) {
			position: absolute;
			&:nth-child(2) {
				top: 1vmin;
			}
			&:nth-child(3) {
				top: 2vmin;
			}
			&:nth-child(4) {
				top: 1.5vmin;
				transform: rotate(90deg);
			}
		}
	}
	&.top-left,
	&.top-right {
		flex-flow: column;
	}
	&.bottom-left,
	&.bottom-right {
		flex-flow: column-reverse;
	}
	&.left {
		flex-flow: row;
	}

	align-items: center;
	position: absolute;

	&.bottom-left,
	&.bottom-right {
		> .card-row {
			flex-direction: row;
		}
	}
	&.top-left,
	&.top-right {
		> .card-row {
			flex-direction: row-reverse;
		}
	}
}

.card-row {
	justify-content: center;
	display: flex;
	flex-wrap: wrap;
	position: relative;

	&:first-child {
		padding: 3vmin 1vmin;
	}
}

.card-stack.mafia {
	z-index: 1;
}

.player-area.top-left {
	top: 0;
	left: 0;
	.card-stack {
		&.mafia {
			margin: 0.5vmin 1vmin;
		}
		transform: rotate(180deg);
	}
}
.player-area.top-right {
	top: 0;
	right: 0;
	.card-stack {
		.mafia {
			margin: 0.5vmin 1vmin;
		}
		transform: rotate(180deg);
	}
}
.player-area.left {
	left: 0;
	.card-stack {
		margin: -1.4vmin 3vmin;
		&.mafia {
			margin: -0.35vmin 3vmin;
		}
		transform: rotate(90deg);
	}
}
.player-area.bottom-left {
	bottom: 0;
	left: 0;
	margin: 0.5vmin 0.25vmin;
	.mafia {
		margin: 0.5vmin 1vmin;
	}
}
.player-area.bottom-right {
	bottom: 0;
	right: 0;
	margin: 0.5vmin 0.25vmin;
	.mafia {
		margin: 0.5vmin 1vmin;
	}
}

.player-info {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.7);
	&.player-active {
		background-color: rgba(20, 135, 163, 0.7);
	}
	padding: 0.75rem;
	z-index: 2;

	.left & {
		@media (orientation: portrait) and (max-width: 50rem) {
			left: 60vw;
		}
		left: 30vmin;
	}
	.bottom-left & {
		bottom: 25vmin;
		right: 3rem;
	}
	.top-left & {
		top: 25vmin;
		right: 3rem;
	}
	.bottom-right & {
		bottom: 25vmin;
		left: 3rem;
	}
	.top-right & {
		top: 25vmin;
		left: 3rem;
	}

	width: max-content;
	// z-index: 999;
}
