.card-clubs-1 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 0%;
}

.card-diamonds-1 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 1.88679%;
}

.card-hearts-1 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 3.77358%;
}

.card-spades-1 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 5.66038%;
}

.card-clubs-2 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 7.54717%;
}

.card-diamonds-2 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 9.43396%;
}

.card-hearts-2 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 11.3208%;
}

.card-spades-2 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 13.2075%;
}

.card-clubs-3 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 15.0943%;
}

.card-diamonds-3 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 16.9811%;
}

.card-hearts-3 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 18.8679%;
}

.card-spades-3 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 20.7547%;
}

.card-clubs-4 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 22.6415%;
}

.card-diamonds-4 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 24.5283%;
}

.card-hearts-4 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 26.4151%;
}

.card-spades-4 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 28.3019%;
}

.card-clubs-5 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 30.1887%;
}

.card-diamonds-5 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 32.0755%;
}

.card-hearts-5 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 33.9623%;
}

.card-spades-5 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 35.8491%;
}

.card-clubs-6 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 37.7359%;
}

.card-diamonds-6 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 39.6226%;
}

.card-hearts-6 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 41.5094%;
}

.card-spades-6 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 43.3962%;
}

.card-clubs-7 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 45.283%;
}

.card-diamonds-7 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 47.1698%;
}

.card-hearts-7 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 49.0566%;
}

.card-spades-7 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 50.9434%;
}

.card-clubs-8 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 52.8302%;
}

.card-diamonds-8 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 54.717%;
}

.card-hearts-8 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 56.6038%;
}

.card-spades-8 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 58.4906%;
}

.card-clubs-9 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 60.3774%;
}

.card-diamonds-9 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 62.2642%;
}

.card-hearts-9 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 64.1509%;
}

.card-spades-9 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 66.0377%;
}

.card-clubs-10 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 67.9245%;
}

.card-diamonds-10 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 69.8113%;
}

.card-hearts-10 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 71.6981%;
}

.card-spades-10 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 73.5849%;
}

.card-clubs-11 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 75.4717%;
}

.card-diamonds-11 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 77.3585%;
}

.card-hearts-11 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 79.2453%;
}

.card-spades-11 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 81.1321%;
}

.card-clubs-12 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 83.0189%;
}

.card-diamonds-12 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 84.9057%;
}

.card-hearts-12 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 86.7925%;
}

.card-spades-12 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 88.6792%;
}

.card-clubs-13 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 90.566%;
}

.card-diamonds-13 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 92.4528%;
}

.card-hearts-13 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 94.3396%;
}

.card-spades-13 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 96.2264%;
}

.card-clubs-14 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 98.1132%;
}

.card-diamonds-14 {
  background-image: url("sheet.39a92918.webp");
  background-position-x: 100%;
}

.card-inner.card-hidden {
  background-image: url("back.1057d651.webp");
  background-size: 100%;
}

.card-inner {
  width: 100%;
  height: 0;
  background-repeat: no-repeat;
  background-size: 5400%;
  padding-top: 140%;
}

.card-outer {
  width: 7vmin;
  box-sizing: border-box;
  border: 1px solid #000;
  border-radius: 4px;
}

.card-outer.card-used {
  border: 2px solid #32c9f7;
  border-radius: 6px;
}

.card-outer.card-used .card-inner {
  background-blend-mode: overlay;
  background-color: #32c9f740;
}

.player-area {
  align-items: center;
  display: flex;
  position: absolute;
}

.player-area.bottom-left .card-stack > :not(:first-child), .player-area.bottom-right .card-stack > :not(:first-child) {
  position: absolute;
}

.player-area.bottom-left .card-stack > :not(:first-child):nth-child(2), .player-area.bottom-right .card-stack > :not(:first-child):nth-child(2) {
  top: 4vmin;
}

.player-area.bottom-left .card-stack > :not(:first-child):nth-child(3), .player-area.bottom-right .card-stack > :not(:first-child):nth-child(3) {
  top: 5vmin;
}

.player-area.bottom-left .card-stack > :not(:first-child):nth-child(4), .player-area.bottom-right .card-stack > :not(:first-child):nth-child(4) {
  top: 4.5vmin;
  transform: rotate(90deg);
}

.player-area.top-left .card-stack > :not(:first-child), .player-area.top-right .card-stack > :not(:first-child) {
  position: absolute;
}

.player-area.top-left .card-stack > :not(:first-child):nth-child(2), .player-area.top-right .card-stack > :not(:first-child):nth-child(2) {
  top: 1vmin;
}

.player-area.top-left .card-stack > :not(:first-child):nth-child(3), .player-area.top-right .card-stack > :not(:first-child):nth-child(3) {
  top: 2vmin;
}

.player-area.top-left .card-stack > :not(:first-child):nth-child(4), .player-area.top-right .card-stack > :not(:first-child):nth-child(4) {
  top: 1.5vmin;
  transform: rotate(90deg);
}

.player-area:not(.left) {
  width: 50%;
}

.player-area.left {
  height: 100%;
}

.player-area.left > .card-row {
  writing-mode: vertical-lr;
  height: 70%;
}

.player-area.left > .card-row > * {
  writing-mode: horizontal-tb;
}

.player-area.left .card-stack > :not(:first-child) {
  position: absolute;
}

.player-area.left .card-stack > :not(:first-child):nth-child(2) {
  top: 1vmin;
}

.player-area.left .card-stack > :not(:first-child):nth-child(3) {
  top: 2vmin;
}

.player-area.left .card-stack > :not(:first-child):nth-child(4) {
  top: 1.5vmin;
  transform: rotate(90deg);
}

.player-area.top-left, .player-area.top-right {
  flex-flow: column;
}

.player-area.bottom-left, .player-area.bottom-right {
  flex-flow: column-reverse;
}

.player-area.left {
  flex-flow: row;
}

.player-area.bottom-left > .card-row, .player-area.bottom-right > .card-row {
  flex-direction: row;
}

.player-area.top-left > .card-row, .player-area.top-right > .card-row {
  flex-direction: row-reverse;
}

.card-row {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  position: relative;
}

.card-row:first-child {
  padding: 3vmin 1vmin;
}

.card-stack.mafia {
  z-index: 1;
}

.player-area.top-left {
  top: 0;
  left: 0;
}

.player-area.top-left .card-stack {
  transform: rotate(180deg);
}

.player-area.top-left .card-stack.mafia {
  margin: .5vmin 1vmin;
}

.player-area.top-right {
  top: 0;
  right: 0;
}

.player-area.top-right .card-stack {
  transform: rotate(180deg);
}

.player-area.top-right .card-stack .mafia {
  margin: .5vmin 1vmin;
}

.player-area.left {
  left: 0;
}

.player-area.left .card-stack {
  margin: -1.4vmin 3vmin;
  transform: rotate(90deg);
}

.player-area.left .card-stack.mafia {
  margin: -.35vmin 3vmin;
}

.player-area.bottom-left {
  margin: .5vmin .25vmin;
  bottom: 0;
  left: 0;
}

.player-area.bottom-left .mafia {
  margin: .5vmin 1vmin;
}

.player-area.bottom-right {
  margin: .5vmin .25vmin;
  bottom: 0;
  right: 0;
}

.player-area.bottom-right .mafia {
  margin: .5vmin 1vmin;
}

.player-info {
  z-index: 2;
  width: max-content;
  background-color: #000000b3;
  padding: .75rem;
  position: absolute;
}

.player-info.player-active {
  background-color: #1487a3b3;
}

.left .player-info {
  left: 30vmin;
}

@media (orientation: portrait) and (max-width: 50rem) {
  .left .player-info {
    left: 60vw;
  }
}

.bottom-left .player-info {
  bottom: 25vmin;
  right: 3rem;
}

.top-left .player-info {
  top: 25vmin;
  right: 3rem;
}

.bottom-right .player-info {
  bottom: 25vmin;
  left: 3rem;
}

.top-right .player-info {
  top: 25vmin;
  left: 3rem;
}

.game-center {
  z-index: 1;
  flex-flow: row;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.deck {
  position: relative;
}

.deck .card-count {
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  color: #fff;
  -webkit-text-stroke: 2px black;
  font-size: 2.5em;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.moves {
  width: max-content;
  height: 100%;
  background-color: #000;
  margin: 0;
}

.move-list {
  flex-flow: row;
  display: flex;
}

.move-header {
  padding: 1.5rem;
}

.move-title {
  font-size: 1.5rem;
  font-weight: 700;
}

.move-column {
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  flex-flow: column;
  flex-grow: 1;
  display: flex;
}

.move-column-header {
  text-align: center;
  font-weight: 700;
}

.move-future, .move-semi .fullturn-notation {
  color: #bbb;
}

.move-active, .move-semi .semiturn-notation {
  font-weight: 600;
}

.move-notation {
  cursor: pointer;
  margin: .25rem 0;
  padding: .25rem .5rem;
  position: relative;
}

:not(:last-child) > .move-notation {
  border-right: .0625rem solid #888;
}

.move-notation:hover {
  background-color: #ffffff26;
}

.move-notation:after {
  content: attr(data-text);
  height: 0;
  color: #0000;
  visibility: hidden;
  font-weight: 700;
  display: block;
  overflow: hidden;
}

.move-bar {
  width: 0;
  height: 100%;
  z-index: -1;
  background-color: #3c3cca;
  margin: -.25rem -.5rem;
  transition: width .15s ease-out;
  position: absolute;
}

.move-semi .move-bar {
  width: 50%;
}

.move-active .move-bar {
  width: 100%;
}

.import-btn {
  float: right;
  color: #ccc;
}

.import-hide {
  opacity: 0;
  pointer-events: none;
}

.import-hide .import {
  display: none;
}

.import-show {
  opacity: 1;
}

.import-show, .import-show .import {
  display: block;
}

.import-wrapper {
  z-index: 4;
  width: 100%;
  height: 100%;
  background-color: #00000080;
  transition: opacity .1s linear;
  position: fixed;
  top: 0;
  left: 0;
}

.import {
  width: max-content;
  background-color: #000;
  margin: 3rem;
  padding: 1rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.import input {
  color: #fff;
  background-color: #0000;
  border: none;
  border-bottom: .125rem solid #888;
}

.import input:focus {
  border-bottom: .175rem solid #fff;
  outline: none;
}

.import textarea {
  color: #fff;
  resize: none;
  background-color: #222;
  border: none;
}

.import-symbol-input {
  width: 2rem;
}

.import-player-input {
  width: 8rem;
}

.import-warning, .import-error {
  font-size: .75rem;
  font-weight: 700;
}

.import-error {
  color: red;
}

.import-warning {
  color: orange;
}

#import-custom-btn {
  float: right;
}

body, html {
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #000;
  margin: 0;
  padding: 0;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  overflow: hidden;
}

@media (max-width: 80rem) {
  html {
    font-size: .7em;
  }
}

#app, .view {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

@media (orientation: portrait) {
  #app, .view {
    flex-flow: column;
  }
}

.game {
  width: 100%;
  height: 100%;
  background-color: #171717;
  margin: 0;
  padding: 0;
  position: relative;
}

/*# sourceMappingURL=index.530d8932.css.map */
