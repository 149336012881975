@use 'sass:math';
@use 'sass:string';
@use 'sass:list';

$card-width: 7vmin;
$card-aspect-ratio: math.div(336px, 240px);

$suits: clubs, diamonds, hearts, spades;

@for $id from 1 through 14 {
	@each $suit in $suits {
		@if $id != 14 or $suit == clubs or $suit == diamonds {
			.card-#{$suit}-#{$id} {
				// background-image: url('../assets/cards/#{$id}#{str-slice(to-upper-case($suit), 0, 1)}.png?as=webp&height=200');
				background-image: url('../assets/cards/sheet.png?as=webp&width=#{168 * 54}');
				background-position-x: math.div(
					(($id - 1) * 4 + list.index($suits, $suit) - 1) * 100%,
					53
				);
				// background-position-x: 0;
				// background-size: cover;
			}
		}
	}
}

.card-inner.card-hidden {
	background-image: url('../assets/back.png?as=webp&width=168');
	background-size: 100% auto;
}

.card-inner {
	background-repeat: no-repeat;

	background-size: 54 * 100%;
	width: 100%;
	height: 0;
	padding-top: $card-aspect-ratio * 100%;
}

.card-outer {
	width: $card-width;
	border: 1px solid black;
	border-radius: 4px;
	box-sizing: border-box;
	&.card-used {
		border: 2px solid #32c9f7;
		border-radius: 6px;
		.card-inner {
			background-blend-mode: overlay;
			background-color: #32c9f740;
		}
	}
}
