.game-center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;

	display: flex;
	flex-flow: row;
	align-items: center;
}

// .action-menu {
// 	display: flex;
// 	flex-flow: column;
// }

// .action-button {
// 	margin: 3px;
// 	font-size: 1em;
// 	padding: 10px;
// 	border: none;
// 	background-color: rgb(0, 32, 214);
// 	color: white;
// 	cursor: pointer;

// 	&:hover {
// 		opacity: 0.75;
// 	}
// 	&:disabled {
// 		background-color: gray;
// 		opacity: 1;
// 		cursor: not-allowed;
// 	}
// }

.deck {
	position: relative;
	.card-count {
		user-select: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;

		color: white;
		font-size: 2.5em;
		-webkit-text-stroke: 2px black;
		font-weight: 700;
	}
}
