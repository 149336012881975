@use 'sass:math';
@use 'cards';
@use 'layout';
@use 'center';
@use 'moves';
@use 'import';

body,
html {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	background-color: black;
	color: white;

	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
html {
	@media (max-width: 80rem) {
		font-size: 0.7em;
	}
}

#app,
.view {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	display: flex;

	@media (orientation: portrait) {
		flex-flow: column;
	}
}

.game {
	background-color: #171717;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	position: relative;
}
