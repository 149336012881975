.import-btn {
	float: right;
	color: #ccc;
}

.import-hide {
	& .import {
		display: none;
	}
	opacity: 0;
	pointer-events: none;
}

.import-show {
	opacity: 1;
	&,
	& .import {
		display: block;
	}
}

.import-wrapper {
	z-index: 4;
	transition: opacity linear 100ms;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.5);
}

.import {
	background-color: #000;
	margin: 3rem;
	padding: 1rem;
	width: max-content;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	position: absolute;

	input {
		background-color: transparent;
		color: white;
		border: none;
		border-bottom: 0.125rem solid #888;

		&:focus {
			outline: none;
			border-bottom: 0.175rem solid white;
		}
	}
	textarea {
		background-color: #222;
		color: white;
		border: none;
		resize: none;
	}
}

.import-symbol-input {
	width: 2rem;
}
.import-player-input {
	width: 8rem;
}

.import-warning,
.import-error {
	font-weight: 700;
	font-size: 0.75rem;
}
.import-error {
	color: red;
}
.import-warning {
	color: orange;
}
#import-custom-btn {
	float: right;
}
