.moves {
	// position: relative;
	margin: 0;
	width: max-content;
	height: 100%;
	background-color: black;
}
.move-list {
	display: flex;
	flex-flow: row;
}

.move-header {
	padding: 1.5rem;
}
.move-title {
	font-size: 1.5rem;
	font-weight: 700;
}

.move-column {
	flex-grow: 1;
	// padding: 0.5rem;

	display: flex;
	flex-flow: column;
	// text-overflow: clip;
	// word-wrap: ;
	user-select: none;
	z-index: 1;
}

.move-column-header {
	text-align: center;
	font-weight: 700;
}

.move-future,
.move-semi .fullturn-notation {
	color: #bbb;
}
.move-active {
	// background-color: #3c3cca;
	font-weight: 600;
}
.move-semi .semiturn-notation {
	font-weight: 600;
}
.move-notation {
	position: relative;
	padding: 0.25rem 0.5rem;
	margin: 0.25rem 0;
	:not(:last-child) > & {
		border-right: 0.0625rem solid #888;
	}
	cursor: pointer;
	&:hover {
		background-color: rgba(255, 255, 255, 0.15);
	}
	&::after {
		display: block;
		content: attr(data-text);
		font-weight: 700;
		height: 0;
		color: transparent;
		overflow: hidden;
		visibility: hidden;
	}
}

.move-bar {
	margin: -0.25rem -0.5rem;
	transition: width ease-out 150ms;
	width: 0;
	.move-semi & {
		width: 50%;
	}
	.move-active & {
		width: 100%;
	}
	height: 100%;
	z-index: -1;
	position: absolute;
	background-color: #3c3cca;
}
